import OnlyHeader from "components/Headers/OnlyHeader";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
} from "reactstrap";
import { updateSubscription } from "store/actions/plateformActions";
import { getAllPricings } from "store/actions/plateformActions";

const ManageSubscriptions = () => {
  const dispatch = useDispatch();
  const { pricingPackages, usersLoader } = useSelector(
    (state) => state.plateform
  );
  const [modal, setModal] = useState(false);
  const [currentState, setCurrentState] = useState("month");
  const [ID, setID] = useState("");
  const [currentSubscriptionFeatures, setcurrentSubscriptionFeatures] =
    useState({});
  const toggle = () => setModal(!modal);

  useEffect(() => {
    dispatch(getAllPricings());
  }, []);

  return (
    <>
      <OnlyHeader />
      <Container className="mt--7 content_container" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <h3 className="mb-0">Manage Subscriptions</h3>
              </CardHeader>
              <Row className="d-flex justify-content-center align-items-center py-4">
                <Nav tabs>
                  <NavItem onClick={() => setCurrentState("month")}>
                    <NavLink
                      href="#"
                      className={currentState === "month" ? "active" : ""}
                    >
                      Monthly
                    </NavLink>
                  </NavItem>
                  <NavItem onClick={() => setCurrentState("year")}>
                    <NavLink
                      href="#"
                      className={currentState === "year" ? "active" : ""}
                    >
                      Yearly
                    </NavLink>
                  </NavItem>
                </Nav>
              </Row>
              {usersLoader ? (
                <Row className="d-flex justify-content-center mb-3">
                  <Spinner color="primary" />
                </Row>
              ) : (
                <Row className="mx-3">
                  {pricingPackages
                    ?.filter(
                      (subscription) => subscription.interval === currentState
                    )
                    .map((subscription) => {
                      return (
                        <Col key={subscription?.id}>
                          <Card className="subscription_card py-4 ">
                            <h1 className="subscription_card-heading text-white">
                              {subscription?.name}
                            </h1>
                            <p className="subscription_card-feature px-3 my-3">
                              Suitable for sending upto 50x Free monthly video
                              messages or simply for giving greetrs a try!
                              <br />
                              usage below:
                            </p>
                            <p className="subscription_card-pricing">
                              <span className="currency">£ </span>
                              <span className="price">
                                {subscription?.amount}
                              </span>{" "}
                              /per month
                            </p>
                            <p className="subscription_card-feature">
                              {subscription?.features?.users} users
                            </p>
                            <p className="subscription_card-feature">
                              {subscription?.features?.videoMessages} video
                              messages
                            </p>
                            {subscription?.features?.videoUrl && (
                              <p className="subscription_card-feature">
                                Video URL
                              </p>
                            )}
                            {subscription?.features?.customCallToAction && (
                              <p className="subscription_card-feature">
                                Custom call-to-action
                              </p>
                            )}
                            {subscription?.features?.standardCallToAction && (
                              <p className="subscription_card-feature">
                                Standard call-to-action
                              </p>
                            )}
                            {subscription?.features?.greeterzLogo && (
                              <p className="subscription_card-feature">
                                Greetrs Logo
                              </p>
                            )}
                            {subscription?.features?.yourLogo && (
                              <p className="subscription_card-feature">
                                Your Logo
                              </p>
                            )}
                            {subscription?.features?.analytics && (
                              <p className="subscription_card-feature">
                                Analytics
                              </p>
                            )}

                            <p className="subscription_card-feature">
                              {subscription?.features?.storage}GB Storage
                            </p>
                            <Button
                              color="primary"
                              size="sm"
                              className="w-75 subscription_card-update"
                              onClick={() => {
                                setID(subscription?.id);
                                setcurrentSubscriptionFeatures(
                                  subscription.features
                                );
                                toggle();
                              }}
                            >
                              Update
                            </Button>
                          </Card>
                        </Col>
                      );
                    })}
                </Row>
              )}
            </Card>
          </div>
        </Row>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle} className="text-capitalize">
            Update Subscription
          </ModalHeader>
          <ModalBody className="py-0">
            <Form>
              <FormGroup>
                <Label>Features</Label>
                <FormGroup className="mx-3">
                  <Label>Users</Label>
                  <Input
                    type="number"
                    min={1}
                    value={currentSubscriptionFeatures?.users}
                    onChange={(e) => {
                      const newVal = parseInt(e.target.value);
                      setcurrentSubscriptionFeatures({
                        ...currentSubscriptionFeatures,
                        users: newVal,
                      });
                    }}
                  />
                </FormGroup>
                <FormGroup className="mx-3">
                  <Label>Video Messages</Label>
                  <Input
                    type="text"
                    value={currentSubscriptionFeatures?.videoMessages}
                    onChange={(e) =>
                      setcurrentSubscriptionFeatures({
                        ...currentSubscriptionFeatures,
                        videoMessages: e.target.value,
                      })
                    }
                  />
                </FormGroup>
                <FormGroup className="mx-3">
                  <Label>Storage</Label>
                  <Input
                    type="number"
                    min={1}
                    value={currentSubscriptionFeatures?.storage}
                    onChange={(e) => {
                      const newVal = parseFloat(e.target.value);
                      setcurrentSubscriptionFeatures({
                        ...currentSubscriptionFeatures,
                        storage: newVal,
                      });
                    }}
                  />
                </FormGroup>

                <FormGroup className="mx-3" check>
                  <Input
                    type="checkbox"
                    checked={currentSubscriptionFeatures?.videoUrl}
                    onChange={(e) =>
                      setcurrentSubscriptionFeatures({
                        ...currentSubscriptionFeatures,
                        videoUrl: e.target.checked,
                      })
                    }
                  />
                  <Label check>Video URL</Label>
                </FormGroup>
                <FormGroup className="mx-3" check>
                  <Input
                    type="checkbox"
                    checked={currentSubscriptionFeatures?.greeterzLogo}
                    onChange={(e) =>
                      setcurrentSubscriptionFeatures({
                        ...currentSubscriptionFeatures,
                        greeterzLogo: e.target.checked,
                      })
                    }
                  />
                  <Label check>Greetrs Logo</Label>
                </FormGroup>
                <FormGroup className="mx-3" check>
                  <Input
                    type="checkbox"
                    checked={currentSubscriptionFeatures?.yourLogo}
                    onChange={(e) =>
                      setcurrentSubscriptionFeatures({
                        ...currentSubscriptionFeatures,
                        yourLogo: e.target.checked,
                      })
                    }
                  />
                  <Label check>Your Logo</Label>
                </FormGroup>
                <FormGroup className="mx-3" check>
                  <Input
                    type="checkbox"
                    checked={currentSubscriptionFeatures?.customCallToAction}
                    onChange={(e) =>
                      setcurrentSubscriptionFeatures({
                        ...currentSubscriptionFeatures,
                        customCallToAction: e.target.checked,
                      })
                    }
                  />
                  <Label check>Custom Call To Action</Label>
                </FormGroup>
                <FormGroup className="mx-3" check>
                  <Input
                    type="checkbox"
                    checked={currentSubscriptionFeatures?.standardCallToAction}
                    onChange={(e) =>
                      setcurrentSubscriptionFeatures({
                        ...currentSubscriptionFeatures,
                        standardCallToAction: e.target.checked,
                      })
                    }
                  />
                  <Label check>Standard Call To Action</Label>
                </FormGroup>
                <FormGroup className="mx-3" check>
                  <Input
                    type="checkbox"
                    checked={currentSubscriptionFeatures?.analytics}
                    onChange={(e) =>
                      setcurrentSubscriptionFeatures({
                        ...currentSubscriptionFeatures,
                        analytics: e.target.checked,
                      })
                    }
                  />{" "}
                  <Label check>Analytics</Label>
                </FormGroup>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle} size="sm">
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={() =>
                dispatch(
                  updateSubscription(ID, currentSubscriptionFeatures, () => {
                    alert("Subscription Updated");
                    toggle();
                  })
                )
              }
              size="sm"
            >
              Done
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </>
  );
};

export default ManageSubscriptions;
