import Repository from "./repository";
const update = "/user/update";
const getUserSubscription = "/payment/get-subscription/";
const Pricing = "/payment/get-packages";
const paymentLink = "/payment/paymentLink";

export default {
  updateUser(payload) {
    return Repository.put(`${update}`, payload);
  },
  userSubscription(payload) {
    console.log({ payload });
    return Repository.get(`${getUserSubscription}${payload}`);
  },
  getPricing() {
    return Repository.get(`${Pricing}`);
  },
  generateEnterprisePayment(payload) {
    return Repository.post(`${paymentLink}`, payload);
  },
};
