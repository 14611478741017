const initState = {
  authError: "",
  uid: "",
  user: null,
  loader: false,
};
const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return {
        ...state,
        uid: action.user?.id,
        user: { ...action.user },
        authError: "",
      };
    case "LOGIN_FAIL":
      localStorage.clear();
      return {
        ...state,
        uid: "",
        authError: action.error.message,
        user: null,
      };
    case "AUTH_LOADER":
      return {
        ...state,
        loader: action.payload,
      };
    case "LOGOUT_SUCCESS":
      return {
        ...state,
        authError: "",
        uid: "",
        user: null,
      };
    default:
      return {
        ...state,
      };
  }
};
export default authReducer;
