import firebase from "../../config/firebase";
import { v4 as uuidv4 } from "uuid";
import { RepositoryFactory } from "repository/RepositoryFactory";
import { LocationRepoFactory } from "repository/Location/LocationRepoFactory";
let User = RepositoryFactory.get("user");
let Location = LocationRepoFactory.get("country");

export const getDashbaordStats = () => async (dispatch) => {
  firebase
    .firestore()
    .collection("dashboardStats")
    .doc("n1QyTJK6SXbtTnvg40Aq")
    .onSnapshot((doc) => {
      dispatch({
        type: "DASHBOARD_STATS",
        payload: doc.data(),
      });
    });
};

export const getAllUsers = () => async (dispatch) => {
  dispatch(Loader(true));
  try {
    firebase
      .firestore()
      .collection("users")
      .orderBy("createdAt", "desc")
      .limit(15)
      .onSnapshot(async (query) => {
        let users = [];
        for (let doc of query.docs) {
          if (doc.exists) {
            if (doc.data().subscriptionId) {
              const data = await User.userSubscription(
                doc.data().subscriptionId
              );
              users.push({
                id: doc.id,
                subscription: data.data.data.plan.nickname,
                ...doc.data(),
              });
            } else {
              users.push({
                id: doc.id,
                ...doc.data(),
              });
            }
          }
        }
        dispatch({
          type: "USERS_DATA",
          payload: users,
        });
        dispatch(Loader(false));
      });
  } catch (error) {
    dispatch(Loader(false));
    console.log("error :", error.message);
  }
};
export const getMoreUsers =
  (item, onError = () => {}) =>
  async (dispatch) => {
    dispatch(loadMoreLoader(true));
    try {
      const querySnapshot = await firebase
        .firestore()
        .collection("users")
        .orderBy("createdAt", "desc")
        .limit(15)
        .startAfter(item.createdAt)
        .get();

      if (querySnapshot.docs.length === 0 || querySnapshot.docs.length < 15) {
        onError();
      }
      let users = [];
      for (let doc of querySnapshot.docs) {
        if (doc.exists) {
          if (doc.data().subscriptionId) {
            const data = await User.userSubscription(doc.data().subscriptionId);
            users.push({
              id: doc.id,
              subscription: data.data.data.plan.nickname,
              ...doc.data(),
            });
          } else {
            users.push({
              id: doc.id,
              ...doc.data(),
            });
          }
        }
      }
      dispatch({
        type: "FETCH_MORE_DATA",
        payload: users,
      });
      dispatch(loadMoreLoader(false));
    } catch (error) {
      console.log("error:", error.message);
      dispatch(loadMoreLoader(false));
      onError(true);
    }
  };

export const updateUserStatus =
  (id, status, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch(statusLoader(true));
    try {
      const updatedStatus = status == "blocked" ? "active" : "blocked";
      firebase
        .firestore()
        .collection("users")
        .doc(id)
        .update({
          status: updatedStatus,
        })
        .then(dispatch(statusLoader(false)), onSuccess());
    } catch (error) {
      dispatch(statusLoader(false));
      console.log("error :", error.message);
    }
  };
export const updateFreeAccess =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch(statusLoader(true));
    try {
      firebase
        .firestore()
        .collection("users")
        .doc(payload.id)
        .update({
          allowFreeAccess: payload.access,
        })
        .then(dispatch(statusLoader(false)), onSuccess());
    } catch (error) {
      dispatch(statusLoader(false));
      console.log("error :", error.message);
    }
  };

export const getSingleUser =
  (id, onSuccess = () => {}) =>
  async (dispatch) => {
    firebase
      .firestore()
      .collection("users")
      .doc(id)
      .get()
      .then((doc) => {
        dispatch({
          type: "SINGLE_USER",
          payload: { id: doc.id, ...doc.data() },
        });
        onSuccess();
      });
  };

export const updateProfileImageAction =
  (payload, imageToReomve, onSuccess = () => {}) =>
  async (dispatch) => {
    let folder = payload.name == "profileImage" ? "profile" : "logo";
    try {
      if (imageToReomve !== undefined) {
        await firebase.storage().refFromURL(imageToReomve).delete();
      }
      const imageFile = payload.image;
      const fileName = payload?.image?.name;
      const fileExtension = fileName.slice(fileName.lastIndexOf("."));
      const fileNameWithExtension = uuidv4() + fileExtension.toLowerCase();
      const storageRef = firebase
        .storage()
        .ref(`user/${folder}/` + fileNameWithExtension);
      const uploadTaskSnapshot = await storageRef.put(imageFile);
      const downloadURL = await uploadTaskSnapshot.ref.getDownloadURL();

      firebase
        .firestore()
        .collection("users")
        .doc(payload.id)
        .update({
          [payload.name]: downloadURL,
        });
      onSuccess();
    } catch (err) {
      onSuccess();
      alert(err.message);
    }
  };
export const updateUserDetails = (payload) => async (dispatch) => {
  dispatch(updateLoader(true));
  try {
    const { data } = await User.updateUser(payload);
    if (data.success) {
      dispatch(updateLoader(false));
      alert(data.message);
    } else {
      dispatch(updateLoader(false));
      alert("error :", data.message);
    }
  } catch (error) {
    dispatch(updateLoader(false));
    console.log("error :", error.message);
  }
};

export const getAllPricings = () => async (dispatch) => {
  dispatch(Loader(true));
  try {
    const { data } = await User.getPricing();
    const packagesPromises = [];

    if (data?.success) {
      data?.data?.forEach((docData) => {
        const promise = firebase
          .firestore()
          .collection("pricings")
          .where("priceId", "==", docData?.id)
          .get()
          .then((query) => {
            const packages = [];
            for (let doc of query.docs) {
              if (doc.exists) {
                packages.push({
                  id: docData?.id,
                  name: docData?.nickname,
                  interval: docData?.interval,
                  currency: docData?.currency,
                  amount: docData?.amount,
                  features: doc?.data()?.features,
                });
              }
            }
            return packages;
          });

        packagesPromises.push(promise);
      });

      const packagesArray = await Promise.all(packagesPromises);
      const packages = packagesArray.flat();

      if (packages.length > 0) {
        dispatch({
          type: "PACKAGES",
          payload: packages,
        });
      } else {
        console.error("No packages found");
      }
    } else {
      console.error("Error loading Data");
    }

    dispatch(Loader(false));
  } catch (error) {
    dispatch(Loader(false));
    console.log(error.message, "error");
  }
};
export const generateEnterpriseLink =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      const { data } = await User.generateEnterprisePayment(payload);
      if (data?.success) {
        navigator.clipboard.writeText(data?.data?.paymentLink);
        alert("Link Copied to Clipboard");
        onSuccess();
      } else {
        console.log(data?.message, "error");
      }
    } catch (error) {
      alert(error.response.data.message);
    }
  };

export const updateSubscription =
  (ID, payload, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      if (!/[^0-9]/.test(payload.videoMessages)) {
        payload.videoMessages = Number(payload.videoMessages);
      }
      const pricingsCollectionRef = firebase.firestore().collection("pricings");
      const querySnapshot = await pricingsCollectionRef
        .where("priceId", "==", ID)
        .get();
      querySnapshot.forEach(async (doc) => {
        await doc.ref.update({
          features: payload,
        });
        dispatch({
          type: "UPDATED_PACKAGES",
          payload: { id: ID, data: payload },
        });
      });

      onSuccess();
    } catch (error) {
      console.error(error.message, "error");
    }
  };

export const Loader = (val) => async (dispatch) => {
  dispatch({
    type: "LOADER",
    payload: val,
  });
};
export const statusLoader = (val) => async (dispatch) => {
  dispatch({
    type: "STATUS_LOADER",
    payload: val,
  });
};
export const updateLoader = (val) => async (dispatch) => {
  dispatch({
    type: "UPDATE_LOADER",
    payload: val,
  });
};

export const getAllCountries = () => async (dispatch) => {
  try {
    const { data } = await Location.getCountries();
    if (!data.error) {
      dispatch({
        type: "COUNTRIES",
        payload: data?.data,
      });
    } else {
      alert(data?.msg);
    }
  } catch (error) {
    alert(error.response.data.msg);
  }
};
export const getAllCities = (country) => async (dispatch) => {
  try {
    let payload = {
      country,
    };
    const { data } = await Location.getCities(payload);
    if (!data.error) {
      dispatch({
        type: "CITIES",
        payload: data?.data,
      });
    } else {
      alert(data?.msg);
    }
  } catch (error) {
    alert(error.response.data.msg);
  }
};

const loadMoreLoader = (val) => async (dispatch) => {
  dispatch({
    type: "Load_More",
    payload: val,
  });
};
