import firebase from "firebase/app";
// import firebase from 'firebase';
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyB-M-GCZJ_QQtqlZ1t5BGTX4TfsnMmVAtA",
  authDomain: "greetrz.firebaseapp.com",
  projectId: "greetrz",
  storageBucket: "greetrz.appspot.com",
  messagingSenderId: "857578385061",
  appId: "1:857578385061:web:b2af2065e35807030c674c",
  measurementId: "G-TSFJEXSPWW",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
export const storage = firebase.storage();
export default firebase;
