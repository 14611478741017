import OnlyHeader from "components/Headers/OnlyHeader";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { generateEnterpriseLink } from "store/actions/plateformActions";
import { getAllUsers } from "store/actions/plateformActions";
const initialData = {
  users: "",
  videoMessages: "",
  storage: "",
  videoUrl: false,
  standardCallToAction: false,
  customCallToAction: false,
  yourLogo: false,
  analytics: false,
  greeterzLogo: false,
};
const initialInterval = ["month", "year"];
const ManageEnterprise = () => {
  const dispatch = useDispatch();
  const { usersData } = useSelector((state) => state.plateform);
  const [enterprise, setEnterprise] = useState([]);
  const [companyID, setCompanyID] = useState("");
  const [Interval, setInterval] = useState("month");
  const [Amount, setAmount] = useState(0);
  const [enterpiseFeatures, setEnterpiseFeatures] = useState(initialData);

  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  useEffect(() => {
    if (usersData && usersData.length > 0) {
      const superAdminUsers = usersData.filter(
        (user) => user.role === "super-admin"
      );
      if (superAdminUsers.length > 0) {
        setEnterprise(superAdminUsers);
      } else {
        setEnterprise([]);
      }
    }
  }, [usersData]);

  return (
    <>
      <OnlyHeader />
      <Container className="mt--7 content_container" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <h3 className="mb-0">Manage Enterprise Subscriptions</h3>
              </CardHeader>
              <CardBody>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (Amount <= 0) {
                      alert("Enter some Amount");
                    } else {
                      if (!/[^0-9]/.test(enterpiseFeatures.videoMessages)) {
                        enterpiseFeatures.videoMessages = Number(
                          enterpiseFeatures.videoMessages
                        );
                      }

                      let obj = {
                        userId: companyID,
                        unitAmount: Amount,
                        currency: "USD",
                        interval: Interval,
                        features: enterpiseFeatures,
                      };
                      dispatch(
                        generateEnterpriseLink(obj, () => {
                          setCompanyID("");
                          setEnterpiseFeatures(initialData);
                          setInterval("month");
                          setAmount(0);
                        })
                      );
                    }
                  }}
                >
                  <FormGroup>
                    <Label for="exampleSelect">Enterprise</Label>
                    <div className="mx-3">
                      <Input
                        id="exampleSelect"
                        name="select"
                        type="select"
                        className="text-white"
                        value={companyID}
                        onChange={(e) => setCompanyID(e.target.value)}
                      >
                        <option value="">Select Enterprise</option>
                        {enterprise?.map((company) => (
                          <option key={company.id} value={company.id}>
                            {company.firstName + " " + company?.lastName}
                          </option>
                        ))}
                      </Input>
                    </div>
                  </FormGroup>

                  <FormGroup className="mx-3">
                    <Label>Amount</Label>
                    <Input
                      type="number"
                      className="text-white"
                      min={1}
                      value={Amount}
                      onChange={(e) => {
                        const newVal = parseInt(e.target.value);
                        setAmount(newVal);
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleSelect">Interval</Label>
                    <div className="mx-3">
                      <Input
                        id="exampleSelect"
                        name="select"
                        type="select"
                        className="text-white text-capitalize"
                        value={Interval}
                        onChange={(e) => setInterval(e.target.value)}
                      >
                        <option value="">Select Interval</option>
                        {initialInterval?.map((interval, index) => (
                          <option
                            key={index}
                            value={interval}
                            className="text-capitalize"
                          >
                            {interval}
                          </option>
                        ))}
                      </Input>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label>Features</Label>
                    <FormGroup className="mx-3">
                      <Label>Users</Label>
                      <Input
                        type="number"
                        className="text-white"
                        min={1}
                        value={enterpiseFeatures?.users}
                        onChange={(e) => {
                          const newVal = parseInt(e.target.value);
                          setEnterpiseFeatures({
                            ...enterpiseFeatures,
                            users: newVal,
                          });
                        }}
                      />
                    </FormGroup>
                    <FormGroup className="mx-3">
                      <Label>Video Messages</Label>
                      <Input
                        type="text"
                        className="text-white"
                        value={enterpiseFeatures?.videoMessages}
                        onChange={(e) => {
                          const newVal = e.target.value;
                          setEnterpiseFeatures({
                            ...enterpiseFeatures,
                            videoMessages: newVal,
                          });
                        }}
                      />
                    </FormGroup>
                    <FormGroup className="mx-3">
                      <Label>Storage</Label>
                      <Input
                        type="number"
                        className="text-white"
                        min={1}
                        value={enterpiseFeatures?.storage}
                        onChange={(e) => {
                          const newVal = parseFloat(e.target.value);
                          setEnterpiseFeatures({
                            ...enterpiseFeatures,
                            storage: newVal,
                          });
                        }}
                      />
                    </FormGroup>

                    <FormGroup className="mx-3" check>
                      <Input
                        type="checkbox"
                        checked={enterpiseFeatures?.videoUrl}
                        onChange={(e) =>
                          setEnterpiseFeatures({
                            ...enterpiseFeatures,
                            videoUrl: e.target.checked,
                          })
                        }
                      />
                      <Label check>Video URL</Label>
                    </FormGroup>
                    <FormGroup className="mx-3" check>
                      <Input
                        type="checkbox"
                        checked={enterpiseFeatures?.greeterzLogo}
                        onChange={(e) =>
                          setEnterpiseFeatures({
                            ...enterpiseFeatures,
                            greeterzLogo: e.target.checked,
                          })
                        }
                      />
                      <Label check>Greetrs Logo</Label>
                    </FormGroup>
                    <FormGroup className="mx-3" check>
                      <Input
                        type="checkbox"
                        checked={enterpiseFeatures?.yourLogo}
                        onChange={(e) =>
                          setEnterpiseFeatures({
                            ...enterpiseFeatures,
                            yourLogo: e.target.checked,
                          })
                        }
                      />
                      <Label check>Your Logo</Label>
                    </FormGroup>
                    <FormGroup className="mx-3" check>
                      <Input
                        type="checkbox"
                        checked={enterpiseFeatures?.customCallToAction}
                        onChange={(e) =>
                          setEnterpiseFeatures({
                            ...enterpiseFeatures,
                            customCallToAction: e.target.checked,
                          })
                        }
                      />
                      <Label check>Custom Call To Action</Label>
                    </FormGroup>
                    <FormGroup className="mx-3" check>
                      <Input
                        type="checkbox"
                        checked={enterpiseFeatures?.standardCallToAction}
                        onChange={(e) =>
                          setEnterpiseFeatures({
                            ...enterpiseFeatures,
                            standardCallToAction: e.target.checked,
                          })
                        }
                      />
                      <Label check>Standard Call To Action</Label>
                    </FormGroup>
                    <FormGroup className="mx-3" check>
                      <Input
                        type="checkbox"
                        checked={enterpiseFeatures?.analytics}
                        onChange={(e) =>
                          setEnterpiseFeatures({
                            ...enterpiseFeatures,
                            analytics: e.target.checked,
                          })
                        }
                      />
                      <Label check>Analytics</Label>
                    </FormGroup>
                  </FormGroup>
                  <Button
                    type="submit"
                    size="sm"
                    color="primary"
                    className="float-right mr-3"
                  >
                    Submit
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ManageEnterprise;
