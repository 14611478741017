import firebase from "../../config/firebase";
// Import the "Loader" action if not already done

export const login = (creds) => {
  return (dispatch) => {
    dispatch(Loader(true));
    firebase
      .auth()
      .signInWithEmailAndPassword(creds.email, creds.password)
      .then((data) => {
        // console.log(data.user?.uid, "uid");
        dispatch(reRegisterSnapshot(data.user?.uid));
        // firebase
        //   .firestore()
        //   .collection("superAdmin")
        //   .doc(data.user?.uid)
        //   .get()
        //   .then((doc) => {
        //     console.log(doc.data());
        //   });
      })
      .catch((error) => {
        dispatch(Loader(false));
        dispatch({
          type: "LOGIN_FAIL",
          uid: "",
          error: error,
        });
        dispatch({
          type: "LOGIN_REQUEST_END",
        });
        alert(error.message);
      });
  };
};

export const reRegisterSnapshot = (id) => async (dispatch) => {
  firebase
    .firestore()
    .collection("superAdmin")
    .doc(id)
    .onSnapshot(async (doc) => {
      dispatch({
        type: "LOGIN_SUCCESS",
        user: { id: doc.id, ...doc.data() },
        error: "",
      });
      dispatch(Loader(false));
      dispatch({
        type: "LOGIN_REQUEST_END",
      });
    });
};

export const logout = () => {
  return (dispatch) => {
    firebase
      .auth()
      .signOut()
      .then((data) => {
        dispatch({
          type: "LOGOUT_SUCCESS",
          uid: "",
          user: "",
          error: "",
        });
        localStorage.clear();
      })
      .catch((error) => {
        dispatch({
          type: "LOGOUT_FAIL",
          uid: "",
          error: error,
        });
      });
  };
};

export const forgetPassword =
  (email, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch(Loader(true));
    try {
      await firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then((res) => {
          dispatch(Loader(false));
          alert("Please check your email and reset the password");
          onSuccess();
        })
        .catch((err) => {
          dispatch(Loader(false));
          alert(err.message);
        });
    } catch (error) {
      dispatch(Loader(false));
      alert(error.message);
    }
  };

export const updateSuperAdmin = (uid, payload) => async (dispatch) => {
  firebase
    .firestore()
    .collection("superAdmin")
    .doc(uid)
    .update({ ...payload })
    .then(
      alert("Admin Updated Successfully"),
      dispatch(reRegisterSnapshot(uid))
    );
};

export const updatePassword =
  (payload, onSuccess = () => {}) =>
  async (dispatch, getState) => {
    dispatch(Loader(true));
    try {
      const user = await firebase.auth().currentUser;
      const credentials = firebase.auth.EmailAuthProvider.credential(
        payload.email,
        payload.oldPassword
      );

      await user.reauthenticateWithCredential(credentials);
      await user.updatePassword(payload.newPassword);
      alert("Password updated successfully!");
      onSuccess();
      dispatch(Loader(false));
    } catch (error) {
      dispatch(Loader(false));
      alert(error?.message);
    }
  };

const Loader = (data) => async (dispatch) => {
  dispatch({
    type: "AUTH_LOADER",
    payload: data,
  });
};
