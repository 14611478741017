import React, { useRef, useState } from "react";
import { Row, Spinner } from "reactstrap";
import { FaUser } from "react-icons/fa";
import { updateProfileImageAction } from "store/actions/plateformActions";
import { useDispatch } from "react-redux";

const ImageUpload = ({ profileImage, ID, name }) => {
  const dispatch = useDispatch();
  const imgRef = useRef(null);
  const [image, setImage] = useState("");
  const [loader, setloader] = useState(false);

  const updateImageProfile = (e) => {
    const file = e.target.files[0];
    setloader(true);
    if (file && file.type.startsWith("image/")) {
      setImage(file);
      let imageToRemove = profileImage;
      let obj = {
        image: file,
        id: ID,
        name: name,
      };
      dispatch(
        updateProfileImageAction(obj, imageToRemove, () => {
          setloader(false);
        })
      );
    } else {
      setloader(false);
      alert("Please select a valid image file.");
    }
  };

  return (
    <div className="image__uploader">
      <Row className="py-4 justify-content-center ">
        <div className="image__uploader-div">
          {loader ? (
            <Spinner size="sm" color="primary" />
          ) : (
            <>
              {profileImage || image ? (
                <img
                  className="profile_image"
                  src={(image && URL.createObjectURL(image)) || profileImage}
                />
              ) : (
                <FaUser className="profile_image" />
              )}
            </>
          )}

          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="image_upload"
            width={20}
            onClick={() => imgRef.current.click()}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
            />
          </svg>
        </div>
      </Row>
      <input
        type="file"
        name="imageURL"
        ref={imgRef}
        onChange={(e) => {
          updateImageProfile(e);
        }}
        hidden
      ></input>
    </div>
  );
};

export default ImageUpload;
