import OnlyHeader from "components/Headers/OnlyHeader";
import ImageUpload from "components/ImageUpload";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { getAllCities } from "store/actions/plateformActions";
import { getAllCountries } from "store/actions/plateformActions";
import { updateUserDetails } from "store/actions/plateformActions";

let user = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-6 h-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
    />
  </svg>
);

const EditUser = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedCountry, setSelectedCountry] = useState("");
  const { singleUser, updateLoader, countries, cities } = useSelector(
    (state) => state.plateform
  );
  // console.log({ cities });

  const initialData = {
    firstName: singleUser.firstName,
    lastName: singleUser.lastName,
    email: singleUser?.email,
    phone: singleUser?.phone,
    country: singleUser?.country,
    city: singleUser?.city,
    companyName: singleUser?.companyName,
  };
  const [editUserData, setEditUserData] = useState(initialData);

  //   console.log(singleUser);
  const handleSubmit = (e) => {
    e.preventDefault();
    let obj = {
      userId: singleUser?.id,
      ...editUserData,
    };
    dispatch(updateUserDetails(obj));
  };

  useEffect(() => {
    dispatch(getAllCountries());
  }, []);
  useEffect(() => {
    if (selectedCountry !== "") {
      dispatch(getAllCities(selectedCountry));
    }
  }, [selectedCountry]);

  return (
    <>
      <OnlyHeader />
      {/* Page content */}
      <Container className="mt--7 content_container" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent d-flex align-items-center">
                <Button
                  size="sm"
                  color="primary"
                  className="mr-2"
                  onClick={() => history.goBack()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    width={14}
                    height={14}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                    />
                  </svg>
                </Button>
                <h3 className="mb-0">Edit User Info</h3>
              </CardHeader>
              <Form onSubmit={handleSubmit}>
                <CardBody>
                  <Row className="px-3">
                    {singleUser?.role == "super-admin" && (
                      <Col className="d-flex flex-column align-items-start">
                        <Label>Company Profile</Label>
                        <ImageUpload
                          profileImage={singleUser?.logo}
                          ID={singleUser?.id}
                          name="logo"
                        />
                      </Col>
                    )}

                    <Col className="d-flex flex-column align-items-start">
                      <Label>Profile</Label>
                      <ImageUpload
                        profileImage={singleUser?.profileImage}
                        ID={singleUser?.id}
                        name="profileImage"
                      />
                    </Col>
                  </Row>
                  <Row className="d-flex flex-column flex-sm-row align-items-start align-items-sm-center my-3">
                    <Col className="col-12 col-sm-2">
                      <Label>Full Name</Label>
                    </Col>
                    <Col>
                      <Input
                        placeholder="First Name"
                        value={editUserData?.firstName}
                        onChange={(e) =>
                          setEditUserData({
                            ...editUserData,
                            firstName: e.target.value,
                          })
                        }
                      />
                    </Col>
                    <Col className="mt-2 mt-sm-0">
                      <Input
                        placeholder="Last Name"
                        value={editUserData?.lastName}
                        onChange={(e) =>
                          setEditUserData({
                            ...editUserData,
                            lastName: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                  {singleUser?.role == "super-admin" && (
                    <Row className="d-flex flex-column flex-sm-row align-items-start align-items-sm-center my-3">
                      <Col className="col-2">
                        <Label>Company</Label>
                      </Col>
                      <Col>
                        <Input
                          placeholder="Company Name"
                          type="text"
                          value={editUserData?.companyName}
                          onChange={(e) =>
                            setEditUserData({
                              ...editUserData,
                              companyName: e.target.value,
                            })
                          }
                        />
                      </Col>
                    </Row>
                  )}

                  <Row className="d-flex flex-column flex-sm-row align-items-start align-items-sm-center my-3">
                    <Col className="col-2">
                      <Label>Telephone</Label>
                    </Col>
                    <Col>
                      <Input
                        placeholder="Telephone"
                        type="number"
                        min={1}
                        value={editUserData?.phone}
                        onChange={(e) =>
                          setEditUserData({
                            ...editUserData,
                            phone: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                  <Row className="d-flex flex-column flex-sm-row align-items-start align-items-sm-center my-3">
                    <Col className="col-2">
                      <Label>Email</Label>
                    </Col>
                    <Col>
                      <Input
                        placeholder="Email"
                        value={editUserData?.email}
                        onChange={(e) =>
                          setEditUserData({
                            ...editUserData,
                            email: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                  <Row className="d-flex flex-column flex-sm-row align-items-start align-items-sm-center my-3">
                    <Col className="col-2">
                      <Label>Country</Label>
                    </Col>
                    <Col>
                      <Input
                        placeholder="Country"
                        type="select"
                        value={editUserData?.country}
                        onChange={(e) => {
                          setSelectedCountry(e.target.value);
                          setEditUserData({
                            ...editUserData,
                            country: e.target.value,
                          });
                        }}
                      >
                        <option>--Select Country--</option>
                        {countries?.map((country) => (
                          <option value={country?.name}>{country?.name}</option>
                        ))}
                      </Input>
                    </Col>
                  </Row>
                  <Row className="d-flex flex-column flex-sm-row align-items-start align-items-sm-center my-3">
                    <Col className="col-2">
                      <Label>City</Label>
                    </Col>
                    <Col>
                      <Input
                        placeholder="City"
                        type="select"
                        value={editUserData?.city}
                        onChange={(e) =>
                          setEditUserData({
                            ...editUserData,
                            city: e.target.value,
                          })
                        }
                      >
                        <option>--Select City--</option>
                        {cities?.map((city) => (
                          <option value={city}>{city}</option>
                        ))}
                      </Input>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Row className="justify-content-end mx-3">
                    <Button type="submit" size="sm" color="primary">
                      {updateLoader ? (
                        <Spinner size="sm" color="light" className="mx-2" />
                      ) : (
                        "Update"
                      )}
                    </Button>
                  </Row>
                </CardFooter>
              </Form>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default EditUser;
