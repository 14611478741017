import OnlyHeader from "components/Headers/OnlyHeader";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import { getMoreUsers, updateFreeAccess } from "store/actions/plateformActions";

import {
  getAllUsers,
  getSingleUser,
  updateUserStatus,
} from "store/actions/plateformActions";

const userRoles = ["super-admin", "admin", "user"];

const Users = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { usersData, usersLoader, statusLoader, loadMoreLoader } = useSelector(
    (state) => state.plateform
  );
  const [role, setRole] = useState("");
  const [filteredRoles, setFilteredRoles] = useState([]);
  const [modal, setModal] = useState(false);
  const [accessModal, setAccessModal] = useState(false);
  const [detailsModal, setDetailsModal] = useState(false);
  const [detailsModalObj, setDetailsModalObj] = useState({});
  const [userStatus, setUserStatus] = useState("");
  const [userDetails, setUserDetails] = useState({ status: "", id: "" });
  const [LoadMoreBtn, setLoadMoreBtn] = useState(false);
  const [allowAccessDetails, setAllowAccessDetails] = useState({
    id: "",
    access: false,
  });
  const [lastItem, setLastItem] = useState({});
  const toggle = () => setModal(!modal);
  const accessToggle = () => setAccessModal(!accessModal);
  const DetailsToggle = () => setDetailsModal(!detailsModal);

  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  useEffect(() => {
    if (role == "") {
      setFilteredRoles(usersData);
    } else {
      const filteredData = usersData.filter((user) => user.role === role);
      setFilteredRoles(filteredData);
    }
  }, [role, usersData]);

  useEffect(() => {
    if (usersData?.length > 0) {
      const lastIndex = usersData.length - 1;
      setLastItem(usersData[lastIndex]);
    }
  }, [usersData]);

  const fetchMoreUsers = () => {
    dispatch(
      getMoreUsers(lastItem, () => {
        setLoadMoreBtn(true);
      })
    );
  };

  return (
    <>
      <OnlyHeader />
      {/* Page content */}
      <Container className="mt--7 content_container" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent d-flex justify-content-between">
                <h3 className="mb-0">Users</h3>
                <Input
                  id="exampleSelect"
                  name="select"
                  type="select"
                  className="text-capitalize w-25"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                >
                  <option value="">Filter By</option>
                  {userRoles?.map((role, index) => {
                    return (
                      <option
                        value={role}
                        key={index}
                        className="text-capitalize"
                      >
                        {role}
                      </option>
                    );
                  })}
                </Input>
              </CardHeader>
              <CardBody>
                {usersLoader ? (
                  <Row className="justify-content-center">
                    <Spinner color="primary" />
                  </Row>
                ) : (
                  <Table
                    className="align-items-center table-flush text-white"
                    responsive
                  >
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        {/* <th scope="col">Role</th>
                        <th scope="col">Subsciption</th>
                        */}
                        <th scope="col">Status</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>

                    <tbody>
                      {filteredRoles?.map((user) => {
                        return (
                          <tr>
                            <td className="text-capitalize">
                              {user?.firstName + " " + user?.lastName}
                            </td>

                            <td>{user?.email}</td>
                            {/* <td className="text-capitalize">{user?.role}</td>
                            <td className="text-capitalize">
                              {user?.subscription}
                            </td>*/}
                            <td className="text-capitalize">{user.status}</td>
                            <td>
                              <Button
                                size="sm"
                                color="primary"
                                onClick={() => {
                                  setDetailsModalObj(user);
                                  DetailsToggle();
                                }}
                              >
                                View Details
                              </Button>
                              {/* <Button
                                size="sm"
                                color={
                                  user?.allowFreeAccess ? "danger" : "primary"
                                }
                                onClick={() => {
                                  // setID(user?.id);
                                  setAllowAccessDetails({
                                    id: user?.id,
                                    access: !user?.allowFreeAccess,
                                  });
                                  accessToggle();
                                }}
                              >
                                {user?.allowFreeAccess
                                  ? "Allowed Free Access"
                                  : "Allow Free Access"}
                              </Button> */}
                              {/* <Button
                                size="sm"
                                color="primary"
                                onClick={() => {
                                  dispatch(
                                    getSingleUser(user?.id, () => {
                                      history.push(
                                        `/admin/edit-user/${user.id}`
                                      );
                                    })
                                  );
                                }}
                              >
                                Edit
                              </Button> */}
                              {/* <Button
                                size="sm"
                                color="danger"
                                onClick={() => {
                                  setUserStatus(
                                    user?.status == "blocked"
                                      ? "Unblock"
                                      : "Block"
                                  );
                                  setUserDetails({
                                    status: user.status,
                                    id: user.id,
                                  });
                                  toggle();
                                }}
                              >
                                {user.status == "blocked" ? "Unblock" : "Block"}
                              </Button> */}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                )}
              </CardBody>
              <Row className="justify-content-end m-4">
                <Button
                  color="primary"
                  size="sm"
                  className="cursor-pointer"
                  onClick={fetchMoreUsers}
                  disabled={LoadMoreBtn || usersLoader}
                >
                  {loadMoreLoader ? (
                    <Spinner size="sm" className="mx-4" />
                  ) : (
                    "Load More"
                  )}
                </Button>
              </Row>
            </Card>
          </div>
        </Row>
        {/* View Details Modal */}
        <Modal isOpen={detailsModal} toggle={DetailsToggle}>
          <ModalHeader toggle={DetailsToggle} className="text-capitalize">
            {detailsModalObj?.firstName + " " + detailsModalObj?.lastName}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <p>Email</p>
                <p>Address</p>
                <p>Role</p>
                <p>Subscription</p>
                <p>Sent Videos</p>
                <p>Opened Videos</p>
                <p>Status</p>
              </Col>
              <Col>
                <p>{detailsModalObj?.email}</p>
                <p>
                  {detailsModalObj?.city !== undefined &&
                  detailsModalObj?.country !== undefined
                    ? detailsModalObj?.city + ", " + detailsModalObj?.country
                    : "N/A"}
                </p>
                <p className="text-capitalize">{detailsModalObj?.role}</p>
                <p className="text-capitalize">
                  {detailsModalObj?.subscription}
                </p>
                <p>
                  {detailsModalObj?.videoStats?.sentVideos !== undefined
                    ? detailsModalObj?.videoStats?.sentVideos
                    : 0}
                </p>
                <p>
                  {detailsModalObj?.videoStats?.openedVideos !== undefined
                    ? detailsModalObj?.videoStats?.openedVideos
                    : 0}
                </p>
                <p className="text-capitalize">{detailsModalObj?.status}</p>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={DetailsToggle} size="sm">
              Cancel
            </Button>
            {detailsModalObj?.role == "super-admin" && (
              <Button
                size="sm"
                color={detailsModalObj?.allowFreeAccess ? "danger" : "primary"}
                onClick={() => {
                  // setID(user?.id);
                  setAllowAccessDetails({
                    id: detailsModalObj?.id,
                    access: !detailsModalObj?.allowFreeAccess,
                  });
                  accessToggle();
                }}
              >
                {detailsModalObj?.allowFreeAccess
                  ? "Allowed Free Access"
                  : "Allow Free Access"}
              </Button>
            )}

            <Button
              size="sm"
              color="danger"
              onClick={() => {
                setUserStatus(
                  detailsModalObj?.status == "blocked" ? "Unblock" : "Block"
                );
                setUserDetails({
                  status: detailsModalObj.status,
                  id: detailsModalObj.id,
                });
                toggle();
              }}
            >
              {detailsModalObj.status == "blocked" ? "Unblock" : "Block"}
            </Button>
            <Button
              size="sm"
              color="primary"
              onClick={() => {
                dispatch(
                  getSingleUser(detailsModalObj?.id, () => {
                    history.push(`/admin/edit-user/${detailsModalObj.id}`);
                  })
                );
              }}
            >
              Edit User
            </Button>
            {/* <Button
              color="primary"
              onClick={() =>
                dispatch(
                  updateUserStatus(userDetails.id, userDetails.status, () => {
                    toggle();
                  })
                )
              }
              size="sm"
            >
              {statusLoader ? (
                <Spinner color="light" size="sm" className="mx-2" />
              ) : (
                "Done"
              )}
            </Button> */}
          </ModalFooter>
        </Modal>
        {/* update status modal */}
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle} className="text-capitalize">
            {userStatus} User
          </ModalHeader>
          <ModalBody>Are you sure you want to {userStatus} this user</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle} size="sm">
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={() =>
                dispatch(
                  updateUserStatus(userDetails.id, userDetails.status, () => {
                    toggle();
                    DetailsToggle();
                  })
                )
              }
              size="sm"
            >
              {statusLoader ? (
                <Spinner color="light" size="sm" className="mx-2" />
              ) : (
                "Done"
              )}
            </Button>
          </ModalFooter>
        </Modal>
        {/* allow free access modal */}
        <Modal isOpen={accessModal} toggle={accessToggle}>
          <ModalHeader toggle={accessToggle} className="text-capitalize">
            {!allowAccessDetails.access ? "Remove" : "Allow"} Free Access
          </ModalHeader>
          <ModalBody>
            Are you sure you want to{" "}
            {!allowAccessDetails.access ? "Remove" : "Allow"} free access to
            this user
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={accessToggle} size="sm">
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={() =>
                dispatch(
                  updateFreeAccess(allowAccessDetails, () => {
                    accessToggle();
                    DetailsToggle();
                  })
                )
              }
              size="sm"
            >
              {statusLoader ? (
                <Spinner color="light" size="sm" className="mx-2" />
              ) : (
                "Done"
              )}
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </>
  );
};

export default Users;
