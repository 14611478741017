import LocationRepository from "./LocationRepository";
const countries = "/capital";
const cities = "/cities";

export default {
  getCountries() {
    return LocationRepository.get(`${countries}`);
  },
  getCities(payload) {
    return LocationRepository.post(`${cities}`, payload);
  },
};
