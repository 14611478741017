const initialState = {
  dashboardStats: {},
  usersData: [],
  singleUser: {},
  usersLoader: false,
  loadMoreLoader: false,
  statusLoader: false,
  updateLoader: false,
  pricingPackages: [],
  countries: [],
};

const plateformReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DASHBOARD_STATS":
      return {
        ...state,
        dashboardStats: action.payload,
      };
    case "Load_More":
      return {
        ...state,
        loadMoreLoader: action.payload,
      };
    case "USERS_DATA":
      return {
        ...state,
        usersData: action.payload,
      };
    case "FETCH_MORE_DATA":
      return {
        ...state,
        usersData: [...state.usersData, ...action.payload],
      };
    case "SINGLE_USER":
      return {
        ...state,
        singleUser: action.payload,
      };
    case "PACKAGES":
      return {
        ...state,
        pricingPackages: action.payload,
      };
    case "UPDATED_PACKAGES":
      const updatedPackages = state.pricingPackages?.map((pkg) => {
        if (pkg.id === action.payload.id) {
          return {
            ...pkg,
            features: action.payload.data,
          };
        }
        return pkg;
      });
      return {
        ...state,
        pricingPackages: updatedPackages,
      };
    case "COUNTRIES":
      return {
        ...state,
        countries: action.payload,
      };
    case "CITIES":
      return {
        ...state,
        cities: action.payload,
      };
    case "LOADER":
      return {
        ...state,
        usersLoader: action.payload,
      };
    case "UPDATE_LOADER":
      return {
        ...state,
        updateLoader: action.payload,
      };
    case "STATUS_LOADER":
      return {
        ...state,
        statusLoader: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default plateformReducer;
