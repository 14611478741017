import OnlyHeader from "components/Headers/OnlyHeader";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { updatePassword } from "store/actions/authActions";
import { updateSuperAdmin } from "store/actions/authActions";

const Profile = () => {
  const dispatch = useDispatch();
  const { user, uid, loader } = useSelector((state) => state.auth);
  const initialData = {
    name: user?.name,
    email: user?.email,
  };
  const [usersData, setUsersData] = useState(initialData);
  const [Password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [cNew, setCNew] = useState("");

  return (
    <div>
      <OnlyHeader />
      <Container className="mt--7 content_container" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <h3 className="mb-0">Update Profile</h3>
              </CardHeader>
              <Form
                className="mx-4"
                onSubmit={(e) => {
                  e.preventDefault();
                  dispatch(updateSuperAdmin(uid, usersData));
                  // console.log({ usersData });
                }}
              >
                <FormGroup className="my-3">
                  <Label>Name</Label>
                  <Input
                    type="text"
                    value={usersData.name}
                    onChange={(e) =>
                      setUsersData({
                        ...usersData,
                        name: e.target.value,
                      })
                    }
                    required
                  ></Input>
                </FormGroup>
                <FormGroup className="my-3">
                  <Label>Email</Label>
                  <Input
                    type="email"
                    value={usersData.email}
                    onChange={(e) =>
                      setUsersData({
                        ...usersData,
                        email: e.target.value,
                      })
                    }
                    required
                  ></Input>
                </FormGroup>
                <Button
                  color="primary"
                  size="sm"
                  className="my-3 float-right"
                  type="submit"
                >
                  Update Email
                </Button>
              </Form>
              <Label className="my-3 mx-4">Update Password</Label>
              <FormGroup className="my-3 mx-4">
                <Input
                  placeholder="Current Password"
                  type="password"
                  value={Password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormGroup>
              <FormGroup className="my-3 mx-4">
                <Input
                  placeholder="New Password"
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </FormGroup>
              <FormGroup className="my-3 mx-4">
                <Input
                  placeholder="Confirm New Password"
                  type="password"
                  value={cNew}
                  onChange={(e) => setCNew(e.target.value)}
                />
              </FormGroup>
              <div>
                <Button
                  color="primary"
                  size="sm"
                  className="mt-3 mb-5 mr-4 float-right"
                  type="button"
                  disabled={Password == "" || newPassword == "" || cNew == ""}
                  onClick={(e) => {
                    if (Password !== "" || newPassword !== "") {
                      if (newPassword == cNew) {
                        let payload = {
                          email: user?.email,
                          oldPassword: Password,
                          newPassword,
                        };
                        dispatch(
                          updatePassword(payload, () => {
                            setPassword("");
                            setNewPassword("");
                            setCNew("");
                          })
                        );
                      }
                    } else {
                      alert("Password can not be empty");
                    }
                  }}
                >
                  {loader ? (
                    <Spinner size="sm" className="mx-4" />
                  ) : (
                    "Update Password"
                  )}
                </Button>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Profile;
